.about-container {
    max-width: 1200px;
    min-height: calc(100vh - 200px);
    margin: 0;
    padding: 0;    

}

.about-container h1 {
    margin-bottom: 2rem;
    color: #333;
}

.about-container p {
    line-height: 1.6;
    color: #666;
    font-size: 1.2rem;
} 