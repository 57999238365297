.followers-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
}

.follower-item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    transition: background-color 0.2s;
}

.follower-item:hover {
    background-color: #f8f9fa;
}

.follower-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #ddd;
}

.avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.follower-details {
    flex-grow: 1;
}

.follower-username {
    font-weight: 600;
    color: #333;
    text-decoration: none;
    font-size: 1rem;
}

.follower-username:hover {
    text-decoration: underline;
}

.follower-name {
    color: #666;
    font-size: 0.9rem;
    margin-top: 4px;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.loading-spinner {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #0a66c2;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
}

.error-message {
    color: #dc3545;
    text-align: center;
    padding: 20px;
}

.no-followers {
    text-align: center;
    padding: 20px;
    color: #666;
    font-size: 16px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.follow-button {
    background-color: #dfdfdf;
    border: 1px solid #d1d1d1;
    color: #333;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    font-size: 0.9rem;
}

.follow-button:not(.is-following) {
    background-color: #007bff;
    color: white;
    border: none;
}

.follow-button.is-following {
    background-color: #ffffff;
    color: #333;
    border: 1px solid #d1d1d1;
}

.follow-button.is-following:hover {
    background-color: #ffebee;
    color: #dc3545;
    border-color: #dc3545;
}

.follow-button:not(.is-following):hover {
    background-color: #0056b3;
}

.follower-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    width: 100%;
}

.follower-info {
    flex-grow: 1;
}

.follower-item .follow-button {
    width: auto;
    min-width: 100px;
}

.username-link,
.follower-username,
.following-username {
    color: #333;
    font-weight: 600;
    text-decoration: none;
}

.username-link:hover,
.follower-username:hover,
.following-username:hover {
    text-decoration: none;
}
