.community-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.community-header {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}

.community-header h1 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 24px;
}

.community-description {
    color: #666;
    margin: 0;
    font-size: 16px;
}

.community-content {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.loading-spinner {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #0079d3;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.community-header-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.community-info {
    flex: 1;
}

.member-count {
    color: #666;
    font-size: 14px;
    margin: 4px 0;
}

.community-button {
    padding: 8px 24px;
    border-radius: 20px;
    font-weight: 600;
    cursor: pointer;
    border: none;
    transition: all 0.2s ease;
}

.community-button.join {
    background-color: #0079d3;
    color: white;
}

.community-button.join:hover {
    background-color: #0061a9;
}

.community-button.leave {
    background-color: transparent;
    color: #0079d3;
    border: 1px solid #0079d3;
}

.community-button.leave:hover {
    background-color: rgba(0, 121, 211, 0.1);
}

.posts-list {
    margin-top: 20px;
}

.post-card {
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
}

.post-card h3 {
    margin: 0 0 12px 0;
    font-size: 18px;
}

.post-card p {
    margin: 0 0 12px 0;
    color: #1a1a1b;
    font-size: 14px;
}

.post-metadata {
    display: flex;
    gap: 16px;
    color: #666;
    font-size: 12px;
}

.no-posts {
    text-align: center;
    color: #666;
    padding: 40px;
}

.community-title-section {
    background-color: white;
    border-bottom: 1px solid #ccc;
    padding: 16px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.community-title-section h1 {
    font-size: 20px;
    margin: 0;
}

.community-actions {
    display: flex;
    gap: 12px;
}

.content-container {
    display: flex;
    gap: 24px;
    margin-top: 24px;
}

.posts-section {
    flex: 1;
    min-width: 0;
}

.community-info-card {
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 12px;
}

.community-info-card h2 {
    font-size: 16px;
    margin: 0 0 12px 0;
    padding-bottom: 8px;
    border-bottom: 1px solid #edeff1;
}

.community-stats {
    display: flex;
    margin: 16px 0;
}

.stat-item {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
}

.stat-value {
    font-size: 16px;
    font-weight: 500;
}

.stat-label {
    font-size: 12px;
    color: #7c7c7c;
}

.created-date {
    font-size: 14px;
    color: #7c7c7c;
    margin-top: 12px;
}

.no-posts {
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 32px;
    text-align: center;
}

.no-posts h3 {
    margin: 0 0 8px 0;
    font-size: 18px;
}

.create-post-button,
.create-post-link {
    background: #0079d3;
    color: white;
    padding: 8px 16px;
    border-radius: 20px;
    border: none;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

.community-button {
    padding: 8px 16px;
    border-radius: 20px;
    font-weight: 600;
    cursor: pointer;
}

.community-button.join {
    background: #0079d3;
    color: white;
    border: none;
}

.community-button.leave {
    background: transparent;
    color: #0079d3;
    border: 1px solid #0079d3;
}

/* Post container styling */
.post {
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
}

.post-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.profile-photo-link {
    margin-right: 8px;
}

.profile-photo {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.post-meta {
    color: #787c7e;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.username-link {
    color: #1c1c1c;
    text-decoration: none;
    font-weight: 600;
}

.post-meta-separator {
    color: #787c7e;
}

.post-date {
    color: #787c7e;
}

.post-content-link {
    display: block;
    text-decoration: none;
    color: inherit;
    margin: 8px 0;
}

.post-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 8px 0;
    color: #1c1c1c;
}

.post-text {
    font-size: 14px;
    line-height: 1.5;
    color: #1c1c1c;
    margin: 8px 0;
}

.post-actions {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 8px;
}

.action-button {
    background: none;
    border: none;
    color: #878a8c;
    font-size: 12px;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    text-decoration: none;
}

.action-button:hover {
    background-color: #f8f9fa;
    border-radius: 2px;
}

.sidebar {
    width: 300px;
    flex-shrink: 0;
}

.post-text {
    margin-top: 12px;
    font-size: 14px;
    line-height: 1.5;
    color: #1c1c1c;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

/* Update existing post-content class if needed */
.post-content {
    padding: 12px;
    flex-grow: 1;
}

.post-link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.post-link:hover {
    background-color: #f8f9fa;
}

.share-button-container {
    position: relative;
}

.share-menu {
    position: absolute;
    bottom: 100%;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 140px;
    margin-bottom: 8px;
    z-index: 1000;
}

.share-menu-item {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 8px 16px;
    border: none;
    background: none;
    cursor: pointer;
    color: #1c1c1c;
    font-size: 14px;
    text-align: left;
}

.share-menu-item:hover {
    background-color: #f8f9fa;
}

.share-menu-item i {
    width: 16px;
}

.profile-photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
    border: 1px solid #ddd;
}

.post-header {
    display: flex;
    align-items: center;
}

.username-link,
.follower-username,
.following-username {
    color: #333;
    font-weight: 600;
    text-decoration: none;
}

.username-link:hover,
.follower-username:hover,
.following-username:hover {
    text-decoration: none;
}

.post-content-link {
    display: block;
    text-decoration: none;
    color: inherit;
    margin: 8px 0;
}

.post-content-link:hover {
    text-decoration: none;
}

.post-actions {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 0;
}

.vote-section {
    display: flex;
    align-items: center;
    margin-right: 16px;
}

.post-title-link {
    text-decoration: none;
    color: inherit;
}

.post-content {
    margin: 8px 0;
}

.truncated-content {
    position: relative;
    word-break: break-word;
}

.read-more {
    color: #0079d3;
    text-decoration: none;
    font-weight: 600;
    margin-left: 5px;
}

.read-more:hover {
    text-decoration: underline;
}

.deleted-message {
    color: #787c7e;
    font-style: italic;
}

/* Rich text content styles */
.post-text p {
    margin: 0 0 0.5em 0;
}

.post-text blockquote {
    border-left: 4px solid #ccc;
    margin: 0 0 1em;
    padding-left: 16px;
    color: #4a4a4a;
}

.post-text pre {
    background: #f8f9fa;
    border-radius: 4px;
    padding: 8px;
    margin: 0 0 1em;
    overflow-x: auto;
}

.post-text a {
    color: #0079d3;
    text-decoration: none;
}

.post-text a:hover {
    text-decoration: underline;
}

.info-section {
    padding: 8px 0;
    font-size: 0.9rem;
    color: #666;
}

.info-divider {
    height: 1px;
    background-color: #eee;
    margin: 8px 0;
}

.admin-label {
    color: #666;
    font-weight: 500;
    margin-bottom: 8px;
}

.admin-info {
    display: flex;
    align-items: center;
    gap: 8px;
}

.admin-image {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #ddd;
}

.creator-link {
    color:  #0079d3;
    text-decoration: none;
}

.creator-link:hover {
    text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .content-container {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        order: -1;
    }
}

@media (max-width: 480px) {
    /* Smaller screens */
    .community-page {
        padding: 12px;
    }

    .post-header {
        flex-wrap: wrap;
    }

    .post-meta {
        width: 100%;
        margin-top: 8px;
    }

    .post-title {
        font-size: 16px;
    }

    .profile-photo {
        width: 32px;
        height: 32px;
    }

    .community-title-section h1 {
        font-size: 18px;
    }

    .community-button,
    .create-post-button,
    .create-post-link {
        padding: 6px 12px;
        font-size: 14px;
    }

    .share-menu {
        right: -8px;
    }

    /* Adjust community info card for very small screens */
    .stat-value {
        font-size: 14px;
    }

    .stat-label {
        font-size: 11px;
    }
}

.preserve-whitespace {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: inherit;
    margin: 0;
}

.truncated-content {
    margin-top: 8px;
}
