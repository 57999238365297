.settings-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    text-align: left;
}

.settings-container h1,
.settings-container h2,
.settings-container p {
    text-align: left;
}

.settings-container section {
    margin-bottom: 2rem;
    padding: 0;
    text-align: left;
}

.username-container {
    display: flex;
    align-items: center;
}

.edit-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: #666;
    padding: 5px;
}

.edit-button:hover {
    color: #333;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
}

.modal-content h2 {
    margin-top: 0;
}

.modal-content input {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.modal-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
}

.modal-buttons button {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.error-message {
    color: red;
    margin: 10px 0;
}

.account-settings-content {
    background: #ffffff;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #d1d1d1;
}

.account-settings-change-password-content {
    background: #ffffff;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #d1d1d1;
    margin-top: 1rem;   
}


/* Grid layout for the fields */
.settings-grid {
    margin-bottom: 2rem;
}

.field-row {
    display: grid;
    grid-template-columns: 150px 1fr 40px;
    align-items: center;
    margin: 10px 0;
}

.field-label {
    font-weight: 500;
    color: #333;
}

.field-value {
    color: #333;
}

.edit-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-button:hover {
    color: #333;
}

.update-button {
    margin-left: 150px;
    background-color: #0066ff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.update-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.success-message {
    color: #28a745;
    margin: 8px 0;
}

.error-message {
    color: #dc3545;
    margin: 8px 0;
}

/* Update password section styles */
.password-field-row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 8px 0;
}

.password-field-row label {
    
    width: 150px;
    font-weight: 500;
    color: #666;
}

.password-field-row input {
    flex: 1;
    max-width: 300px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}