/* src/Views/HomePage.css */
.fencerstats-homepage {
    max-width: 1200px;
    min-height: calc(80vh - 0px);
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.fencerstats-homepage h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    max-width: 800px;
}

.fencerstats-homepage p {
    font-size: 1.5rem;
    color: #555;
}

.fencerstats-coming-soon {
    color: #61dafb;
    font-weight: bold;
    font-size: 2rem;
    margin-top: 2rem;
}

.signup-button-homepage {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.signup-button-homepage:hover {
    background-color: #0056b3;
    text-decoration: none;
}

.welcome-section {
    text-align: center;
    padding: 2rem 0;
    margin-bottom: 2rem;
    width: 100%;
}

.latest-activity-section {
    width: 100%;
    margin: 0 auto;
    max-width: none !important;
    padding: 0 1rem;
}

.latest-activity-section h2 {
    margin-bottom: 1.5rem;
}

.feed-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feed-post {
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1rem;
}

/* Reuse the feed styles from Dashboard.css */

