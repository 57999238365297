.about-community {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 16px;
}

.about-community h2 {
    font-size: 14px;
    font-weight: 500;
    color: #1c1c1c;
    margin-bottom: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid #edeff1;
}

.community-info {
    font-size: 14px;
}

.members-count {
    margin: 12px 0;
    padding: 12px 0;
    border-top: 1px solid #edeff1;
    border-bottom: 1px solid #edeff1;
    display: flex;
    flex-direction: column;
}

.members-count span:first-child {
    font-size: 16px;
    font-weight: 500;
}

.members-count span:last-child {
    font-size: 12px;
    color: #7c7c7c;
}

.created-date {
    font-size: 12px;
    color: #7c7c7c;
    margin-top: 12px;
}

.about-community-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 12px;
}

.about-community-image {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #ddd;
}

.about-community-image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    padding: 16px;
    border-bottom: 1px solid #ddd;
}

.about-community-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #ddd;
}

.about-community-content {
    padding: 0 16px 16px;
}

.about-community-content h2 {
    margin-bottom: 16px;
}
