.communities-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px;
}

.communities-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
}


.create-community-button {
    background-color:  #0079d3;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease;
}

.create-community-button:hover {
    background-color: #0061a9;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    border-radius: 8px;
    padding: 24px;
    width: 800px;
    max-width: 95vw;
}

.modal-content h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.modal-content p {
    color: #666;
    margin-bottom: 1.5rem;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    min-height: 100px;
    max-height: 300px;
    resize: vertical;
    box-sizing: border-box;
}

.modal-buttons {
    display: flex;
    gap: 12px;
    margin-top: 20px;
}

.cancel-button {
    background-color: #e2e2e2;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.create-button {
    background-color: #0079d3;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.error-message {
    color: #dc3545;
    margin-top: 0.5rem;
    font-size: 14px;
}

.community-creation-container {
    display: flex;
    gap: 24px;
}

.community-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;
}

.community-preview {
    background: #f6f7f8;
    border-radius: 4px;
    padding: 20px;
    width: 100%;
    margin: 20px 0;
    box-sizing: border-box;
}

.community-form input,
.community-form textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 8px;
}

.preview-content {
    max-width: 600px;
    margin: 0 auto;
}

.preview-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 8px;
}

.preview-stats {
    font-size: 12px;
    color: #787c7e;
    margin-bottom: 12px;
}

.preview-description {
    color: #1c1c1c;
    font-size: 14px;
    line-height: 1.5;
}

.input-prefix-container {
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px 8px;
    margin-top: 8px;
}

.input-prefix {
    color: #787c7e;
    margin-right: 4px;
}

.input-prefix-container input {
    border: none;
    outline: none;
    flex: 1;
    padding: 4px;
}

.input-prefix-container.error {
    border-color: #ff4500;
}

.error-message {
    color: #ff4500;
    font-size: 12px;
    margin-top: 4px;
}

.community-preview {
    background: #f6f7f8;
    padding: 20px;
    border-radius: 4px;
}

.community-preview h3 {
    margin: 0;
    margin-bottom: 8px;
}

.preview-members {
    color: #666;
    font-size: 12px;
    margin-bottom: 12px;
}

.preview-description {
    color: #333;
    font-size: 14px;
}

input.error,
.input-prefix-container.error {
    border-color: #ff4500;
}

.modal-content {
    max-width: 800px;
}

.communities-list {
    margin-top: 20px;
}

.community-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 16px;
    transition: all 0.2s ease;
}

.community-card:hover {
    border-color: #0079d3;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.community-link {
    display: block;
    padding: 16px;
    text-decoration: none;
    color: inherit;
}

.community-link h2 {
    margin: 0;
    margin-bottom: 8px;
    font-size: 18px;
    color: #1a1a1b;
}

.community-link p {
    margin: 0;
    color: #666;
    font-size: 14px;
}

.member-count {
    margin-top: 8px !important;
    color: #0079d3 !important;
    font-size: 12px !important;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.loading-spinner {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #0079d3;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Preview styles */
.preview-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
}

.preview-stats {
    font-size: 12px;
    color: #787c7e;
}

.preview-description {
    margin-top: 12px;
    color: #1c1c1c;
    font-size: 14px;
}

.community-card-content {
    display: flex;
    align-items: center;
    gap: 16px;
}

.community-card-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #ddd;
}

.community-card-info {
    flex: 1;
}
