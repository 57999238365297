.profile-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    text-align: left;
}

.profile-info {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
}

.profile-info h2 {
    margin-top: 0;
    margin-bottom: 15px;
    color: #333;
}

.profile-info p {
    margin: 10px 0;
}

.profile-info a {
    color: #007bff;
    text-decoration: none;
}

.profile-info a:hover {
    text-decoration: underline;
}

.profile-layout {
    display: grid;
    grid-template-columns: 300px minmax(0, 1fr); /* Fixed left column, flexible right column */
    gap: 2rem;
    margin-top: 2rem;
}

.profile-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensures full width of the sidebar column */
}

.profile-image-container {
    width: 100%; /* Ensures full width within sidebar */
    max-width: 300px;
    aspect-ratio: 1; /* Makes container square */
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #d1d1d1;
}

.profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This ensures the image covers the container without distortion */
}

.profile-content {
    min-width: 0; /* Prevents content from breaking the layout */
    background: #ffffff;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #d1d1d1;
}

/* Make it responsive */
@media (max-width: 768px) {
    .profile-layout {
        grid-template-columns: 1fr; /* Stack columns on mobile */
    }

    .profile-sidebar {
        width: 100%;
        position: static;
    }

    .profile-image-container {
        max-width: 200px; /* Smaller image on mobile */
        margin: 0 auto 2rem; /* Center image and add bottom margin */
    }
}

.biography-text {
    white-space: pre-wrap;
    line-height: 1.5;
}

.profile-info dt {
    font-weight: 500;
    color: #333;
    padding-top: 4px;
}

.profile-info dd {
    margin: 0;
    line-height: 1.6;
}

.profile-info dl {
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: 1rem;
    align-items: start;
    margin: 10px 0;
}

.profile-info .field-value,
.profile-info .biography-value,
.profile-info .milestones-value {
    line-height: 1.6;
    margin: 0;
}

.message-button {
    width: 100%; /* Takes full width of parent container */
    padding: 0.5rem;
    margin-top: 1rem;
    /* Remove max-width if it exists */
}

.message-button {
    background-color: #ffffff;
    border: 1px solid #d1d1d1;
    color: #333;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.message-button:hover {
    background-color: #f3f2ef;
}

.profile-sidebar .follow-button {
    width: 100%;
    padding: 8px 16px;
    margin-top: 8px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
    background-color: #0a66c2;
    color: white;
    border: none;
}

.profile-sidebar .follow-button.is-following {
    background-color: white;
    color: #333;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
}

.profile-sidebar .follow-button.is-following:hover {
    background-color: #ffebee;
    color: #dc3545;
    border-color: #dc3545;
}

.follow-stats {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin: 16px 0;
    width: 100%;
}

.follow-stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: inherit;
}

.follow-stat:hover {
    color: #0a66c2;
}

.follow-count {
    font-weight: 600;
    font-size: 1.1rem;
}

.follow-label {
    color: #666;
    font-size: 0.9rem;
}

.follow-stat:hover .follow-label {
    color: #0a66c2;
}

.report-profile-button {
    width: 100%;
    padding: 8px 16px;
    margin-top: 8px;
    border-radius: 4px;
    cursor: pointer;
    background: none;
    border: none;
    color: #787c7e;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    transition: background-color 0.2s;
}

.report-profile-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.report-profile-button i {
    font-size: 12px;
}

.membership-tags {
    display: flex;
    gap: 8px;
    align-items: center;
}

.role-tag {
    background-color: #e8f0fe;
    color: #1a73e8;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 14px;
    display: inline-block;
}

.edit-profile-button {
    background-color: #f0f0f0;
    color: #666;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease;
}

.edit-profile-button:hover {
    background-color: #e0e0e0;
}

.videos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.profile-content .field-row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
}

.profile-content .field-label {
    flex: 0 0 150px;
    font-weight: 500;
    color: #666;
    padding-top: 2px;
}

.profile-content .field-value {
    flex: 1;
    word-break: break-word;
}

/* Specific style for biography text which might be longer */
.biography-text {
    white-space: pre-wrap;
}

/* Update the field styles to be profile-specific */
.profile-field-row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
}

.profile-field-label {
    flex: 0 0 150px;
    font-weight: 500;
    color: #666;
    padding-top: 2px;
}

.profile-field-value {
    flex: 1;
    word-break: break-word;
}

/* Specific styles for biography and milestones */
.profile-biography-text,
.profile-milestones-text {
    white-space: pre-wrap;
    line-height: 1.5;
}
