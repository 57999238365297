.message-thread {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
}

.messages-list {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.message {
    display: flex;
    flex-direction: column;
    max-width: 70%;
    margin: 4px 0;
}

.message.sent {
    align-self: flex-end;
}

.message.received {
    align-self: flex-start;
}

.message-content {
    padding: 10px 15px;
    border-radius: 15px;
    margin-bottom: 4px;
}

.message.sent .message-content {
    background-color: #007bff;
    color: white;
    border-bottom-right-radius: 5px;
}

.message.received .message-content {
    background-color: #e9ecef;
    color: black;
    border-bottom-left-radius: 5px;
}

.message-timestamp {
    font-size: 0.7rem;
    color: #666;
    margin-top: 2px;
    padding: 0 5px;
}

.message-input-form {
    border-top: 1px solid #e0e0e0;
    padding: 16px;
    background-color: #fff;
}

.message-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    resize: none;
    font-family: inherit;
    margin-bottom: 8px;
    outline: none;
}

.message-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.message-tools {
    display: flex;
    gap: 8px;
}

.tool-button {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    border-radius: 50%;
}

.tool-button:hover {
    background-color: #f5f5f5;
}

.send-button {
    padding: 8px 16px;
    background-color: #0a66c2;
    color: white;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.2s;
}

.send-button:hover {
    background-color: #0056b3;
}

.send-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #666;
    padding: 20px;
}

.messages-list::-webkit-scrollbar {
    width: 6px;
}

.messages-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.messages-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.messages-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.message-thread-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    color: #666;
}

.loading-spinner {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #007bff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.no-messages {
    text-align: center;
    padding: 20px;
    color: #666;
}

.message-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.message-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    display: block;
}

.avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.message-bubble {
    flex-grow: 1;
}

.message-username {
    font-size: 0.85rem;
    font-weight: 500;
    color: #666;
    text-decoration: none;
    margin-bottom: 2px;
    display: block;
}

.message-username:hover {
    text-decoration: underline;
}

.message.sent {
    flex-direction: row-reverse;
}

.message.sent .message-wrapper {
    flex-direction: row-reverse;
}
