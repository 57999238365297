.hamburger {
    display: none;  /* Hidden by default on large screens */
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
}

.hamburger span {
    width: 2rem;
    height: 0.25rem;
    background: #333;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
}

/* Hamburger animation */
.hamburger.open span:first-child {
    transform: rotate(45deg);
}

.hamburger.open span:nth-child(2) {
    opacity: 0;
}

.hamburger.open span:last-child {
    transform: rotate(-45deg);
}

/* Show hamburger on mobile */
@media (max-width: 768px) {
    .hamburger {
        display: flex;
    }
} 