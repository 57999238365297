.create-post-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.create-post-page h1 {
    margin-bottom: 20px;
    font-size: 24px;
}
