/* Base layout */
.club-profile-layout {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 24px;
    max-width: 1200px;
    margin: 0 auto;
}

/* Left sidebar */
.club-sidebar {
    width: 300px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
}

.club-image-container {
    width: 100%;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: white;
    box-sizing: border-box;
}

.club-image {
    width: 100%;
    height: auto;
    border-radius: 4px;
    display: block;
    max-width: 100%;
    object-fit: contain;
}

.club-creation-info {
    width: 100%;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
    box-sizing: border-box;
}

/* Right content area */
.club-content {
    min-width: 0;
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #d1d1d1;
    width: 100%;
    box-sizing: border-box;    
}

.club-section {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
}

.club-section:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.club-section h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 16px;
}

/* Weapons section */
.weapons-tags {
    display: flex;
    gap: 8px;
}

.weapon-tag {
    background: #f0f0f0;
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 0.9rem;
}

/* Admin section */
.admin-info {
    display: flex;
    align-items: center;
    gap: 8px;
}

.admin-image {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
}

.admin-label {
    color: #666;
    font-weight: 500;
    margin-bottom: 8px;
}

.creator-link {
    color:  #0079d3;
    text-decoration: none;
}

.creator-link:hover {
    text-decoration: underline;
}

/* Make it responsive */
@media (max-width: 768px) {
    .club-profile-layout {
        grid-template-columns: 1fr;
    }

    .club-sidebar {
        position: static;
        width: 100%;
    }

    .club-image-container {
        max-width: 200px;
        margin: 0 auto 2rem;
    }
}

.club-info-section {
    width: 100%;
    font-size: 14px;
    color: #666;
}

.club-info-divider {
    width: 100%;
    height: 1px;
    background-color: #eee;
    margin: 8px 0;
}

.club-creator-link {
    color:  #0079d3;
    text-decoration: none;
}

.club-creator-link:hover {
    text-decoration: underline;
}

.social-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.social-link-row {
    padding: 8px 0;
}

.social-link-row a {
    color: #0066cc;
    text-decoration: none;
    transition: color 0.2s ease;
}

.social-link-row a:hover {
    color: #004499;
    text-decoration: underline;
}

.follow-button {
    width: 100%;
    padding: 8px 16px;
    margin-top: 12px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-bottom: 8px;
}

.follow-button:not(.is-following) {
    background-color: #0079d3;
    color: white;
    border: none;
}

.follow-button.is-following {
    background-color: #f8f9fa;
    color: #1c1c1c;
    border: 1px solid #ccc;
}

.follow-button.is-following:hover {
    background-color: #ffebee;
    color: #d32f2f;
    border-color: #d32f2f;
}

.follow-stats {
    display: flex;
    justify-content: center;
    padding: 12px 0;
    border-bottom: 1px solid #eee;
    margin-bottom: 12px;
}

.follow-stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: inherit;
    padding: 0 12px;
}

.follow-stat:hover {
    color: #0079d3;
}

.follow-count {
    font-weight: 600;
    font-size: 16px;
}

.follow-label {
    font-size: 12px;
    color: #787c7e;
}
