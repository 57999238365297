.reply-form-wrapper {
    margin: 8px 0 8px 24px;
    padding-left: 12px;
    border-left: 2px solid #e5e5e5;
}

.comment-form {
    margin-top: 8px;
}

.form-actions {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    margin-top: 8px;
}

.cancel-button {
    padding: 6px 12px;
    border: 1px solid #ccc;
    background: transparent;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button {
    padding: 6px 12px;
    background: #0079d3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button:disabled {
    background: #cccccc;
    cursor: not-allowed;
} 