.inbox-container {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 20px;
    min-height: calc(100vh - 140px); /* Adjust based on your header/footer height */
    border: 1px solid #ddd;
    border-radius: 8px;
    background: white;
    overflow: hidden;
}

/* Sidebar Styles */
.inbox-sidebar {
    background: white;
    padding: 20px;
    border-right: 1px solid #ddd;
    margin: -1px;
}

.inbox-sidebar h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.5rem;
}

.conversation-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.conversation-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
    border: 1px solid #eee;
}

.conversation-item:hover {
    background-color: #f8f9fa;
}

.conversation-item.selected {
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.conversation-avatar {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.conversation-content {
    flex-grow: 1;
    min-width: 0;
}

.conversation-name {
    font-weight: bold;
    color: #333;
    display: block;
    margin-bottom: 4px;
}

.conversation-preview {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.conversation-date {
    font-size: 0.8rem;
    color: #999;
}

/* Messages Area Styles */
.inbox-messages {
    background: white;
    padding: 20px;
    margin: -1px;
}

.messages-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.message-card {
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    padding: 1rem;
}

.message-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    color: #666;
    font-size: 0.9rem;
}

.message-content {
    color: #333;
    line-height: 1.5;
}

.no-messages {
    text-align: center;
    color: #666;
    padding: 2rem;
}

.message-date {
    color: #999;
}

.no-conversations {
    padding: 20px 0;
    color: #787c7e;
    text-align: left;
}

.no-conversations p {
    margin: 8px 0;
}
