.setup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 64px);
    padding: 2rem;
}

.setup-box {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.setup-box h1 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #1c1c1c;
}

.setup-box p {
    color: #666;
    margin-bottom: 1.5rem;
}

.input-group {
    margin-bottom: 1.5rem;
}

.input-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #1c1c1c;
    font-weight: 500;
}

.input-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.2s ease;
}

.input-group input:focus {
    border-color: #0079d3;
    outline: none;
}

.input-group input:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.error-message {
    color: #ff4444;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.success-message {
    color: #28a745;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.setup-account-button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.setup-account-button.active {
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.setup-account-button.disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

.setup-account-button[disabled] {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    opacity: 1;
}

.session-expired {
    text-align: center;
    padding: 2rem;
}

.action-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 2rem;
}

.primary-button {
    background-color: #007bff;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.secondary-button {
    background-color: #6c757d;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.primary-button:hover,
.secondary-button:hover {
    opacity: 0.9;
}
