.club-edit-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.back-button {
    background-color: #f0f0f0;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease;
}

.back-button:hover {
    background-color: #e0e0e0;
}

.club-edit-content {
    background: white;
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 30px;
}

.edit-section {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
}

.edit-section:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.edit-section h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.5rem;
}

/* Field styles specific to club edit */
.field-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.field-label {
    width: 150px;
    font-weight: 500;
    color: #666;
}

.field-value {
    flex: 1;
    margin: 0 15px;
}

.edit-button {
    background: none;
    border: none;
    color: #999;
    cursor: pointer;
    padding: 5px;
}

.edit-button:hover {
    color: #666;
}

.modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
}

.modal-content input,
.modal-content textarea {
    width: calc(100% - 24px);
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 1rem;
    font-size: 14px;
    line-height: 1.5;
    box-sizing: border-box;
}

.modal-content textarea {
    min-height: 150px;
    resize: vertical;
}

.modal-content .textarea-input {
    width: calc(100% - 24px);
    margin: 12px;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    min-height: 150px;
    resize: vertical;
    font-size: 14px;
    line-height: 1.5;
    box-sizing: border-box;
}

/* Similarly for regular input fields */
.modal-content input {
    width: calc(100% - 24px);
    margin: 12px;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
}





.user-result:last-child {
    border-bottom: none;
}

.user-result:hover {
    background-color: #f5f5f5;
}

.user-result.selected {
    background-color: #e6f0ff;
}

.user-result .username {
    margin-left: 12px;
}


.loading {
    padding: 8px;
    text-align: center;
    color: #666;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.search-results-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 4px;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1004;
}

.user-result {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s;
    user-select: none;
}

.user-result:hover {
    background-color: #f5f5f5;
}

.user-result.selected {
    background-color: #e6f0ff;
}

.user-result .username {
    margin-left: 8px;
    flex-grow: 1;
}


.loading {
    padding: 8px 12px;
    text-align: center;
    color: #666;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 16px;
    position: static;
}


.error-container {
    max-width: 600px;
    margin: 100px auto;
    padding: 24px;
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.error-container h2 {
    color: #dc3545;
    margin-bottom: 16px;
}

.error-container p {
    margin-bottom: 24px;
    color: #666;
}

.back-button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.back-button:hover {
    background-color: #0056b3;
}

.error-section {
    background: white;
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 60px 40px;
    text-align: center;
    width: 100%;
    max-width: 800px;
    margin: 120px auto;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.error-section h2 {
    color: #333;
    font-size: 28px;
    font-weight: 500;
    margin: 0;
}

.error-section p {
    color: #666;
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
}

.error-section .button-primary {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 14px 32px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    min-width: 200px;
    margin: 0 auto;
}

.error-section .button-primary:hover {
    background-color: #0056b3;
}


/* Center the layout */
.club-edit-layout {
    width: 100%;
    display: grid;
    grid-template-columns: 300px minmax(0, 1fr);
    gap: 2rem;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.club-edit-sidebar {
    width: 300px;
    margin: 0;
    padding: 0;
    position: sticky;
    top: 2rem;
}

.club-edit-image-container {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #d1d1d1;
    margin-bottom: 1rem;
}

.club-edit-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.club-edit-image-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.club-edit-upload-button {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    background-color: #dfdfdf;
    color: #333;
    text-align: center;
    margin: 0;
}

.club-edit-delete-button {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    border: 1px solid #dc3545;
    background-color: #fff;
    color: #dc3545;
    text-align: center;
    margin: 0;
}

/* Access Denied specific styles */
.access-denied-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 200px);
    width: 100%;
}

.access-denied-section {
    background: white;
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 60px 40px;
    text-align: center;
    width: 100%;
    max-width: 800px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.access-denied-section h2 {
    color: #333;
    font-size: 28px;
    font-weight: 500;
    margin: 0;
}

.access-denied-section p {
    color: #666;
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
}

.access-denied-section .button-primary {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 14px 32px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    min-width: 200px;
    margin: 0 auto;
}

.access-denied-section .button-primary:hover {
    background-color: #0056b3;
}

/* Add these new modal-specific styles */
.club-edit-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.club-edit-modal-content {
    background: white;
    padding: 24px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.club-edit-modal-content h2 {
    margin: 0;
    font-size: 20px;
    color: #333;
}

.club-edit-modal-content input,
.club-edit-modal-content textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 8px;
    box-sizing: border-box;
    max-width: 100%;
}

.club-edit-modal-content textarea {
    min-height: 100px;
    resize: vertical;
}

.club-edit-modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 16px;
}

.club-edit-modal-buttons button {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.club-edit-modal-buttons button:first-child {
    background-color:  #0079d3;
    color: white;
    border: none;
}

.club-edit-modal-buttons button:last-child {
    background: transparent;
    border: 1px solid #ccc;
    color: #1c1c1c;
}

/* Add specific styles for url type inputs if needed */
.club-edit-modal-content input[type="url"] {
    overflow: hidden;
    text-overflow: ellipsis;
}

.club-edit-user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 12px;
    flex-shrink: 0;
    object-fit: cover;
    border: 1px solid #ddd;
}

.club-edit-user-result {
    display: flex;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    transition: background-color 0.2s;
    border-bottom: 1px solid #eee;
}

.club-edit-user-result:hover {
    background-color: #f8f9fa;
}

.club-edit-username {
    flex: 1;
    text-align: left;
}
