.comments-section {
    margin-top: 20px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
}

.comment-form {
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}

.comments-section textarea {
    width: 100%;
    min-height: 100px;
    max-height: 300px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    box-sizing: border-box;
    margin-bottom: 8px;
}

.comment-form button {
    background-color: #0079d3;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
}

.comment-form button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.post-comment-container {
    margin-bottom: 16px;
    width: 100%;
    overflow: visible;
}

.post-comment-content {
    width: 100%;
    height: auto;
    overflow: visible;
    padding: 8px 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.post-comment-text {
    width: 100%;
    height: auto !important;
    overflow: visible !important;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    line-height: 1.5;
}

.comment {
    margin-bottom: 16px;
    width: 100%;
    height: auto !important;
    overflow: visible !important;
}

.comment-content {
    width: 100%;
    height: auto !important;
    overflow: visible !important;
    padding: 8px 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.comment-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
}

.comment-author {
    font-size: 12px;
    font-weight: 500;
    color: #1c1c1c;
}

.comment-time {
    font-size: 12px;
    color: #787c7e;
}

.comment-text {
    width: 100%;
    height: auto !important;
    overflow: visible !important;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    line-height: 1.5;
}

.comment-text p {
    margin: 0 0 1em 0;
}

.comment-text blockquote {
    border-left: 4px solid #ccc;
    margin: 0 0 1em;
    padding-left: 16px;
    color: #4a4a4a;
}

.comment-text pre {
    background: #f8f9fa;
    border-radius: 4px;
    padding: 8px;
    margin: 0 0 1em;
    overflow-x: auto;
}

.comment-text a {
    color: #0079d3;
    text-decoration: none;
}

.comment-text a:hover {
    text-decoration: underline;
}

.comment-actions {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 8px 0;
}

.reply-form-container {
    margin-top: 12px;
    margin-left: 16px;
    border-left: 2px solid #e5e5e5;
    padding-left: 12px;
}

.action-button {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    border: none;
    background: transparent;
    color: #878A8C;
    cursor: pointer;
    font-size: 12px;
}

.action-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.action-button i {
    font-size: 14px;
}

.reply-button {
    background: none;
    border: none;
    color: #878a8c;
    font-size: 12px;
    font-weight: 700;
    padding: 4px 8px;
    cursor: pointer;
}

.reply-button:hover {
    background-color: #f8f9fa;
    border-radius: 2px;
}

.comment-replies {
    margin-left: 24px;
    padding-left: 16px;
    border-left: 2px solid #edeff1;
}

.comment-author-info {
    display: flex;
    align-items: center;
    gap: 16px;
}

.comment-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}

.comment-author {
    font-weight: 600;
    color: #333;
    font-size: 1rem;
}

.comment-author-link {
    text-decoration: none;
    font-size: 12px;
    color: #333;
    font-weight: 600;
}

.comment-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 8px;
}

.comment-author-info {
    display: flex;
    align-items: center;
}

.vote-container {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    margin-right: 12px;
    vertical-align: middle;
}

.vote-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    color: #878a8c;
    font-size: 16px;
    line-height: 1;
}

.vote-button:hover {
    background-color: #f8f9fa;
    border-radius: 2px;
}

.vote-button.active-upvote {
    color: #00b300;
}

.vote-button.active-downvote {
    color: #ff4500;
}

.vote-button:hover.upvote {
    color: #00b300;
}

.vote-button:hover.downvote {
    color: #ff4500;
}

.vote-count {
    font-size: 12px;
    font-weight: 600;
    color: #1c1c1c;
    min-width: 20px;
    text-align: center;
}

.share-button-container {
    position: relative;
}

.share-menu {
    position: absolute;
    bottom: 100%;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 140px;
    margin-bottom: 8px;
    z-index: 1000;
}

.share-menu-item {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 8px 16px;
    border: none;
    background: none;
    cursor: pointer;
    color: #1c1c1c;
    font-size: 14px;
    text-align: left;
}

.share-menu-item:hover {
    background-color: #f8f9fa;
}

.share-menu-item i {
    width: 16px;
}

.deleted-message {
    color: #787c7e;
    font-style: italic;
    font-size: 14px;
}

.edit-comment-form {
    margin: 8px 0;
}

.edit-comment-form .ql-container {
    min-height: 100px;
    max-height: 300px;
    margin-bottom: 8px;
}

.edit-comment-form .ql-editor {
    min-height: 100px;
    max-height: 300px;
    font-size: 14px;
    line-height: 1.5;
}

.edit-comment-form .ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #f8f9fa;
    border-color: #edeff1;
}

.edit-actions {
    display: flex;
    gap: 8px;
    margin-top: 12px;
}

.cancel-button,
.save-button {
    padding: 4px 12px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
}

.cancel-button {
    background: transparent;
    border: 1px solid #ccc;
    color: #1c1c1c;
}

.save-button {
    background: #0079d3;
    border: none;
    color: white;
}

.save-button:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

.edit-indicator {
    color: #787c7e;
    font-size: 12px;
}

.comment-container {
    height: auto !important;
    overflow: visible !important;
    padding: 16px;
    margin-bottom: 16px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}
