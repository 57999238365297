.signup-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 120px);
    padding: 20px;
    background-color: #f5f5f5;
}

.signup-container {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.signup-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
}

.form-group {
    margin-bottom: 1rem;
    width: 100%;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.email-button,
.google-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 1rem;
}

.email-button {
    background-color: #007bff;
    color: white;
}

.email-button:hover {
    background-color: #0056b3;
}

.google-button {
    background-color: white;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.google-button:hover {
    background-color: #f8f9fa;
}

.google-button img {
    width: 18px;
    height: 18px;
}

.error {
    color: #dc3545;
    text-align: center;
    margin-bottom: 1rem;
}

.confirmation-message {
    text-align: center;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.confirmation-message h2 {
    color: #28a745;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
}

.confirmation-message .email-address {
    font-weight: bold;
    color: #007bff;
    margin: 1rem 0;
    word-break: break-all;
    font-size: 1.1rem;
}

.confirmation-message .instructions {
    color: #666;
    margin: 1.5rem 0;
    line-height: 1.6;
    font-size: 1rem;
}

.back-button {
    background-color: #e2e2e2;
    color: #333;
    border: 1px solid #ddd;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
}

.back-button:hover {
    background-color: #f8f9fa;
}

.confirmation-message {
    text-align: center;
}

.confirmation-message h2 {
    color: #28a745;
    margin-bottom: 1.5rem;
}

.email-address {
    color: #0079d3;
    font-weight: 600;
    margin: 1rem 0;
}

.instructions {
    color: #666;
    margin: 1.5rem 0;
    line-height: 1.5;
}

.signup-back-button {
    background-color: #f8f9fa;
    color: #1c1c1c;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-top: 1rem;
    transition: background-color 0.2s ease;
}

.signup-back-button:hover {
    background-color: #e9ecef;
}

.email-verification h1 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.facebook-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.facebook-button:hover {
    background-color: #f5f5f5;
}

.facebook-button img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.divider {
    margin: 1.5rem 0;
    position: relative;
    text-align: center;
}

.divider:before {
    left: 0;
}

.divider:after, .divider:before {
    background-color: #ddd;
    content: "";
    height: 1px;
    position: absolute;
    top: 50%;
    width: 45%;
}


.resend-button:disabled {
    color: #999;
    cursor: not-allowed;
}

.resend-button {
    background-color: #e2e2e2;
    color: #333;
    border: 1px solid #ddd;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
}

.resend-button:hover {
    background-color: #f8f9fa;
}


.verification-input {
    letter-spacing: 0.5em;
    text-align: center;
}

.error {
    color: #ff4444;
    margin: 1rem 0;
    text-align: center;
}

.instructions {
    text-align: center;
    margin: 16px 0;
}

.resend-button {
    display: block;
    margin: 8px auto;
    padding: 8px 16px;
    background-color: #e2e2e2;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
}

.resend-button:hover {
    background-color: #d4d4d4;
}

.terms-agreement {
    margin: 15px 0;
}

.terms-agreement p {
    font-size: 0.75rem;
    color: #666;
    line-height: 1.4;
}

.terms-agreement a {
    color: #0079d3;
    text-decoration: none;
}

.terms-agreement a:hover {
    text-decoration: underline;
}

