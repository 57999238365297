.search-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px;
}

.search-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
}

.search-type-tabs {
    display: flex;
    gap: 12px;
    margin-bottom: 24px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 12px;
}

.search-type-tabs button {
    padding: 8px 16px;
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 500;
    color: #666;
    transition: all 0.2s ease;
}

.search-type-tabs button.active {
    background-color:  #0079d3;
    color: white;
}

.search-type-tabs button:hover:not(.active) {
    background-color: #f0f0f0;
}

.community-link, .user-result, .post-result {
    text-decoration: none;
    color: inherit;
}

.loading-container {
    text-align: center;
    padding: 40px;
    color: #666;
}

.no-results {
    text-align: center;
    padding: 40px;
    color: #666;
}

.user-result {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: left;
}

.user-header {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #ddd;
}

.user-title {
    font-size: 18px;
    font-weight: 500;
    color: #1c1c1c;
    text-align: left;
}

.user-description {
    color: #666;
    font-size: 14px;
    text-align: left;
    padding-left: 44px;
}

.user-bio {
    color: #666;
    font-size: 14px;
    margin-top: 4px;
    text-align: left;
    padding-left: 44px;
}

.community-title {
    font-size: 18px;
    font-weight: 500;
    color: #1c1c1c;
    margin-bottom: 8px;
}

.community-description {
    color: #666;
    font-size: 14px;
    margin-bottom: 8px;
}

.member-count {
    color: #666;
    font-size: 12px;
}

.communities-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
    width: 100%;
}

.community-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: all 0.2s ease;
}

.community-card:hover {
    border-color: #0079d3;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.community-card.user-card {
    width: 100%;
}

.community-link {
    display: block;
    padding: 16px;
    text-decoration: none;
    color: inherit;
}

.communities-list[data-type="communities"] {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.search-results-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    border-radius: 4px;
}

.user-result-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: all 0.2s ease;
    width: 100%;
}

.user-result-card:hover {
    border-color: #0079d3;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.user-result-card a {
    text-decoration: none;
    color: inherit;
}

.user-result-card a:hover {
    background-color: transparent;
}

.user-result {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
}

.user-header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.user-title {
    font-size: 18px;
    font-weight: 500;
    color: #1c1c1c;
}

.user-description {
    color: #666;
    font-size: 14px;
    padding-left: 44px;
}

.user-bio {
    color: #666;
    font-size: 14px;
    margin-top: 4px;
    padding-left: 44px;
}

.user-result:hover {
    background-color: transparent !important;
}

.search-result-item:hover {
    background-color: transparent !important;
}

/* Override any other hover backgrounds */
.community-link:hover,
.search-result-item:hover {
    background-color: transparent !important;
}

.search-result-link {
    text-decoration: none;
    color: inherit;
    display: block;
    padding: 16px;
}

.search-result-link:hover {
    background-color: transparent;
}

.search-results-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.search-result-link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.post-result {
    background: white;
    padding: 1rem;
    transition: background-color 0.2s ease;
}


.post-meta {
    color: #666;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
}

.post-title {
    color: #1a1a1b;
    font-size: 1.1rem;
    margin: 0.3rem 0 0.5rem 0;
    font-weight: 500;
    line-height: 1.3;
}

.post-stats {
    color: #666;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.4rem;
}

.post-stats .dot {
    font-size: 0.5rem;
    color: #878a8c;
}

.post-stats .match-type {
    color:  #0079d3;
}

.search-result-link {
    text-decoration: none;
    color: inherit;
    display: block;
    margin-bottom: 0.8rem;
}

.comment-result {
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 12px;
    background-color: white;
}

.comment-context h3 {
    font-size: 1.1em;
    margin: 8px 0;
    color: #1a1a1a;
}

.comment-content {
    color: #4a4a4a;
    margin: 8px 0;
    line-height: 1.4;
}

.comment-indicator {
    font-size: 0.8em;
    color: #666;
    background: #f0f0f0;
    padding: 2px 6px;
    border-radius: 4px;
}

.search-result-link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.search-result-link:hover .comment-result {
    border-color: #ccc;
    background-color: #fafafa;
}

.comment-search-result {
    padding: 12px 16px;
}

.comment-header {
    font-size: 12px;
    color: #787c7e;
    margin-bottom: 8px;
}

.comment-header .community-link {
    color: #1a1a1b;
    font-weight: 500;
    text-decoration: none;
}

.comment-header .community-link:hover {
    text-decoration: underline;
}

.post-context {
    margin-bottom: 12px;
}

.post-title-link {
    color: #1a1a1b;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    line-height: 1.4;
}

.post-title-link:hover {
    text-decoration: underline;
}

.comment-body {
    background: #f8f9fa;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 8px;
}

.comment-metadata {
    font-size: 12px;
    color: #787c7e;
    margin-bottom: 8px;
}

.comment-author {
    color: #1a1a1b;
    font-weight: 500;
    text-decoration: none;
}

.comment-author:hover {
    text-decoration: underline;
}

.comment-content {
    font-size: 14px;
    line-height: 1.5;
    color: #1a1a1b;
    margin: 8px 0;
}

.comment-stats {
    font-size: 12px;
    color: #787c7e;
}

.comment-thread-section {
    padding: 8px 0;
    border-top: 1px solid #edeff1;
    margin-top: 8px;
}

.comment-thread-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.go-to-thread {
    color: #0079d3;
    font-weight: 500;
    text-decoration: none;
    font-size: 12px;
}

.go-to-thread:hover {
    text-decoration: underline;
}

.comment-post-stats {
    color: #787c7e;
    font-size: 12px;
}

.dot {
    margin: 0 4px;
}

.comment-result-card {
    background: white;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    margin-bottom: 12px;
    transition: border-color 0.2s ease;
}

.comment-result-card:hover {
    border-color: #0079d3;
}


.club-card {
    background-color: var(--background-secondary);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
}

.club-card-content {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}

.club-card-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}

.club-card-info {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.club-card-info h2 {
    margin: 0;
    font-size: 1.2rem;
    color: var(--text-primary);
}

.club-card-info p {
    margin: 0;
    color: var(--text-secondary);
}

.follower-count {
    font-size: 0.9rem;
}

.weapons-list {
    display: flex;
    gap: 0.5rem;
}

.weapon-tag {
    padding: 0.25rem 0.5rem;
    background-color: var(--background-tertiary);
    border-radius: 4px;
    font-size: 0.8rem;
}
