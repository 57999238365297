.settings-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
    box-sizing: border-box;
}


.settings-grid {
    margin-bottom: 2rem;
    width: 100%;
    box-sizing: border-box;
}

.field-row {
    display: grid;
    grid-template-columns: 150px 1fr 40px;
    align-items: start;
    gap: 1rem;
    margin: 10px 0;
    width: 100%;
    box-sizing: border-box;
}

.field-label {
    font-weight: 500;
    color: #333;
}

.field-value {
    color: #333;
    overflow-wrap: break-word;
}

.edit-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-left: auto;
}

.edit-button:hover {
    color: #333;
}

.upload-image-button {
    background-color: #dfdfdf;
    border: 1px solid #d1d1d1;
    color: #333;
    padding: 8px 16px;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.upload-image-button:hover {
    background-color: #f3f2ef;
}

.profile-layout {
    width: 100%;
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 2rem;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.profile-sidebar {
    width: 300px;
    margin: 0;
    padding: 0;
    position: sticky;
    top: 2rem;
}

.profile-image-container {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #d1d1d1;
    margin-bottom: 1rem;
}

.profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-content {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 1.5rem;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #d1d1d1;
    overflow: hidden;
    min-width: 0;
}

.profile-image-upload {
    text-align: center;
    margin-top: 1rem;
}

.profile-image-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.delete-button {
    width: 100%;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    border: none;
    font-size: 14px;
    text-align: center;
}

.upload-button {
    background-color: #f5f5f5;
    border: 1px solid #d1d1d1;
    color: #333;
    padding: 8px 16px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.3s ease;
}

.upload-button:hover {
    background-color: #f3f2ef;
}

.delete-button {
    background-color: #fff;
    color: #dc3545;
    border: 1px solid #dc3545;
}

.upload-button:disabled,
.delete-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .profile-layout {
        grid-template-columns: 1fr;
    }

    .profile-sidebar {
        width: 100%;
        position: static;
    }

    .profile-image-container {
        max-width: 200px;
        margin: 0 auto 1rem;
    }

    .profile-image-upload {
        margin-bottom: 2rem;
    }

    .profile-image-actions {
        max-width: 200px;
        margin: 1rem auto 2rem;
    }

    .settings-container {
        padding: 1rem;
    }
}

/* Update the label styling */
label.upload-button {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    background-color: #dfdfdf;
    color: #333;
    text-align: center;
    margin: 0;
}

/* Keep existing button styles */
.delete-button {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    border: 1px solid #dc3545;
    background-color: #fff;
    color: #dc3545;
    text-align: center;
    margin: 0;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
}

.modal-content h2 {
    margin-top: 0;
}

.modal-content input,
.modal-content select {
    width: 100%;
    padding: 8px;
    margin: 1rem 0;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.modal-buttons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 1rem;
}

.modal-buttons button {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.modal-buttons button:first-child {
    background-color: #007bff;
    color: white;
    border: none;
}

.modal-buttons button:last-child {
    background-color: #e2e2e2;
    border: none;
}

.error-message {
    color: #dc3545;
    margin-top: 0.5rem;
}

.biography-input {
    width: 100%;
    padding: 8px;
    margin: 1rem 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    min-height: 100px;
}

.follow-stats {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 16px;
    padding: 12px;
    border-top: 1px solid #eee;
}

.follow-stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: inherit;
}

.follow-count {
    font-weight: 600;
    font-size: 1.1em;
    color: #333;
}

.follow-label {
    font-size: 0.9em;
    color: #666;
}

.follow-stat:hover .follow-count {
    color:  #0079d3;
}

.follow-stat:hover .follow-label {
    color:  #0079d3;
}

.membership-tags {
    display: flex;
    gap: 8px;
    align-items: center;
}

.role-tag {
    background-color: #e8f0fe;
    color: #1a73e8;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 14px;
    display: inline-block;
}

.checkbox-group {
    display: flex;
    gap: 20px;
    margin: 16px 0;
}

.checkbox-label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.video-input-row {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
}

.video-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.remove-video-button {
    background: none;
    border: none;
    color: #dc3545;
    cursor: pointer;
    padding: 5px;
}

.add-video-button {
    display: flex;
    align-items: center;
    gap: 5px;
    background: none;
    border: 2px dashed #ccc;
    padding: 8px 16px;
    color: #666;
    cursor: pointer;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
}

.add-video-button:hover {
    border-color: #999;
    color: #333;
}
