.terms-container {
    max-width: 1200px;
    margin: 0;
    padding: 0;
}

.terms-container h1 {
    margin-bottom: 2rem;
    color: #333;
}

.terms-container section {
    margin-bottom: 2rem;
}

.terms-container h2 {
    color: #444;
    margin-bottom: 1rem;
}

.terms-container p {
    line-height: 1.6;
    color: #666;
} 