.alert {
    padding: 1rem;
    margin: 0 auto 1rem auto;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    animation: slideDown 0.3s ease-out;
}

.alert-success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.alert-error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.alert-message {
    flex-grow: 1;
}

.alert-close {
    background: none;
    border: none;
    color: inherit;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0 0.5rem;
    margin-left: 1rem;
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
