.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 24px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
}

.modal-content h2 {
    margin-top: 0;
    margin-bottom: 16px;
    color: #1c1c1c;
}

.modal-content p {
    color: #4a4a4a;
    margin-bottom: 24px;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.delete-button {
    background-color: #ff4500;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
}

.delete-button:hover {
    background-color: #cc3700;
}

.cancel-button {
    background-color: #edeff1;
    color: #1c1c1c;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
}

.cancel-button:hover {
    background-color: #e2e4e6;
}
