.privacy-container {
    max-width: 1200px;
    margin: 0;
    padding: 0;
}

.privacy-container h1 {
    margin-bottom: 2rem;
    color: #333;
}

.privacy-container section {
    margin-bottom: 2rem;
}

.privacy-container h2 {
    color: #444;
    margin-bottom: 1rem;
}

.privacy-container p {
    line-height: 1.6;
    color: #666;
}

.privacy-container ul {
    margin-left: 2rem;
    margin-top: 0.5rem;
}

.privacy-container li {
    margin-bottom: 0.5rem;
    color: #666;
} 