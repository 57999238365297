.page-wrapper {
    background-color: #ffffff;
    width: 100%;
    border: 1px solid #d1d1d1;
}

.page-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem;
    box-sizing: border-box;
}

.content-layout {
    width: 100%;
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 2rem;
    margin-top: 2rem;
}

/* Reset any potential browser defaults */
main {
    width: 100%;
    margin: 1px 0 0 0;
    padding: 0;
    background-color: #ffffff;
    min-height: calc(80vh - 0px);
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
/*    border-bottom: 1px solid #e5e5e5; */
    margin: 0 0 2rem 0;
    padding: 0;
}

.page-header .title-section {
    display: flex;
    flex-direction: column;
}

.page-header h1 {
    margin: 0;
    font-size: 1.8rem;
    color: #333;
    font-weight: bold;
}

.page-header .username-display {
    color: #666;
    font-size: 1.1rem;
    margin-top: 0.25rem;
}

/* Primary Button - Blue, high emphasis actions */
.button-primary {
    background-color:  #0079d3;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: normal;
    font-size: 14px;
    text-decoration: none;
    transition: background-color 0.2s ease;
}

.button-primary:hover {
    background-color:#0061a9 ;
}

/* Secondary Button - Light Grey, medium emphasis actions */
.button-secondary {
    background-color: #f0f0f0;
    color: #666;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: normal;
    font-size: 14px;
    text-decoration: none;
    transition: background-color 0.2s ease;
}

.button-secondary:hover {
    background-color: #e0e0e0;
}

/* Tertiary Button - White with border, low emphasis actions */
.button-tertiary {
    background-color: white;
    color: #666;
    border: 1px solid #ddd;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
}

.button-tertiary:hover {
    background-color: #f8f8f8;
    border-color: #ccc;
}

/* Danger Button - For destructive actions */
.button-danger {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease;
}

.button-danger:hover {
    background-color: #c82333;
}

.action-buttons {
    display: flex;
    gap: 8px;
    align-items: center;
}

.edit-profile-button {
    background-color: #f0f0f0;
    color: #666;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease;
}

.edit-profile-button:hover {
    background-color: #e0e0e0;
}

/* Size modifiers */
.button-small {
    padding: 4px 12px;
    font-size: 0.875rem;
}

.button-large {
    padding: 12px 24px;
    font-size: 1.125rem;
}

/* Add to existing SharedStyles.css */
.button-icon {
    background: none;
    border: none;
    color: #999;
    cursor: pointer;
    padding: 5px;
    transition: color 0.2s ease;
}

.button-icon:hover {
    color: #666;
}

/* Base button styles */
button, 
.button-primary,
.button-secondary,
.edit-button,
.back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;  /* Space between icon and text if present */
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.5;
    border: none;
}

/* Modal buttons specific styling */
.modal-buttons {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    margin-top: 16px;
}

.modal-buttons button {
    min-width: 80px;  /* Ensure consistent button width */
}

/* Edit button specific styling */
.edit-button {
    padding: 8px;  /* Equal padding for square icon buttons */
}

/* Add these to existing SharedStyles.css */
.info-grid {
    display: grid;
    gap: 12px;
}

.info-row {
    display: flex;
    align-items: center;
}

.info-label {
    width: 120px;
    color: #666;
    font-weight: 500;
}

.info-value {
    flex: 1;
}
