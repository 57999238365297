.community-edit-section {
    padding: 24px 0;
    border: none;
}

.community-edit-section:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.community-edit-section h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.5rem;
}

.community-field-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.community-field-label {
    width: 150px;
    font-weight: 500;
    color: #666;
}

.community-field-value {
    flex: 1;
    margin: 0 15px;
}

.community-edit-button {
    background: none;
    border: none;
    color: #999;
    cursor: pointer;
    padding: 5px;
}

.community-edit-button:hover {
    color: #666;
}

.community-moderator-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.community-moderator-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #ddd;
}

.community-success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.community-error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.basic-information,
.administration {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
}


.edit-container {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
}

.section-divider {
    border-top: 1px solid #ddd;
    margin: 24px 0;
}

.basic-information,
.administration {
    padding: 0 24px;
}

.basic-information h2,
.administration h2 {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1b;
}

.community-edit-modal-content {
    background: white;
    padding: 24px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.community-edit-container {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
}

.community-edit-section:first-child {
    border-bottom: 1px solid #ddd;
    margin-bottom: 24px;
}

.community-edit-section:last-child {
    padding-bottom: 0;
}

.community-edit-modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 16px;
}

.community-edit-modal-content input,
.community-edit-modal-content textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 8px;
    box-sizing: border-box;
    max-width: 100%;
}

.community-edit-modal-content textarea {
    min-height: 100px;
    resize: vertical;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.community-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 24px;
    background: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 24px;
}

.community-edit-image {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #ddd;
}

.community-image-actions {
    display: flex;
    gap: 8px;
}

.button-tertiary {
    background: none;
    border: 1px solid #dc3545;
    color: #dc3545;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.button-tertiary:hover {
    background: #fff5f5;
}

.button-tertiary:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.community-edit-layout {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 24px;
    margin-top: 24px;
}

.community-edit-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.community-edit-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 300px;
}

.community-edit-image-container {
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
}

.community-edit-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.community-edit-image-actions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.community-edit-content {
    background: white;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #ddd;
} 