.moderator-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.moderator-modal-content {
    background: white;
    padding: 24px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
}


.moderator-modal-content input,
.moderator-modal-content textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 8px;
    box-sizing: border-box;
    max-width: 100%;
}

.moderator-modal-content h2 {
    margin: 0 0 16px 0;
    font-size: 20px;
    color: #333;
}


.search-results {
    flex: 1;
    min-height: 150px;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 16px;
}


.moderator-user-result {
    display: flex;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    transition: background-color 0.2s;
    border-bottom: 1px solid #eee;
}

.moderator-user-result:hover {
    background-color: #f8f9fa;
}

.moderator-search-modal .user-result:last-child {
    border-bottom: none;
}

.moderator-search-modal .user-result:hover {
    background-color: #f8f9fa;
}

.moderator-search-modal .user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 12px;
    flex-shrink: 0;
}

.moderator-search-modal .username {
    flex: 1;
    text-align: left;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
}

.cancel-button {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #ccc;
    color: #1c1c1c;
    font-weight: 600;
}

.loading {
    text-align: center;
    padding: 16px;
    color: #666;
} 



.moderator-user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 12px;
    flex-shrink: 0;
    object-fit: cover;
    border: 1px solid #ddd;
}