.search-box {
    position: relative;
    width: 100%;
    max-width: 400px;
}

.search-form {
    width: 100%;
}

.search-input-container {
    position: relative;
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.search-input-container input {
    flex: 1;
    padding: 8px 12px;
    border: none;
    outline: none;
    font-size: 14px;
    background: transparent;
}

.search-selector {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    padding: 0 8px;
    border-left: 1px solid #ccc;
    margin-left: 8px;
}

.search-selector i {
    font-size: 12px;
    color: #666;
    margin-left: 4px;
}

.search-type-selector {
    padding: 8px;
    border: none;
    background: transparent;
    font-size: 14px;
    color: #666;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.search-type-selector:focus {
    outline: none;
}

.search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    max-height: 400px;
    overflow-y: auto;
    z-index: 1000;
}

.search-result-item {
    padding: 12px;
    cursor: pointer;
    border-bottom: 1px solid #e1e1e1;
}

.search-result-item:last-child {
    border-bottom: none;
}

.search-result-item:hover {
    background-color: #f8f8f8;
}

/* User Result Styles */
.user-result .user-header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.user-info {
    display: flex;
    flex-direction: column;
}

.username {
    font-weight: 500;
    color: #1a1a1a;
}

.display-name {
    font-size: 0.9em;
    color: #666;
}

/* Community Result Styles */
.community-result .community-title {
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 4px;
}

.community-description {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 4px;
}

.member-count {
    font-size: 0.8em;
    color: #888;
}

/* Post Result Styles */
.post-result .post-meta {
    font-size: 0.8em;
    color: #666;
    margin-bottom: 4px;
}

.post-result .post-title {
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 4px;
}

.post-stats {
    font-size: 0.8em;
    color: #666;
    display: flex;
    align-items: center;
    gap: 8px;
}

.dot {
    font-size: 0.8em;
} 

.comment-result {
    padding: 12px;
}

.comment-context {
    font-weight: 500;
    color: #1a1a1a;
    margin: 4px 0;
}

.comment-content {
    color: #666;
    font-size: 0.9em;
    margin: 4px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.comment-indicator {
    font-size: 0.8em;
    color: #666;
    background: #f0f0f0;
    padding: 2px 6px;
    border-radius: 4px;
} 

.club-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #ddd;
}

.club-result .club-title {
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 4px;
}

.club-info {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 4px;
}

/* Club Result Styles */
.club-result {
    padding: 12px;
}

.club-header {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.club-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #ddd;
}

.club-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.club-title {
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 2px;
}

.club-location {
    font-size: 0.9em;
    color: #666;
}

.club-stats {
    font-size: 0.8em;
    color: #888;
}

.weapons-list {
    display: flex;
    gap: 6px;
    margin-top: 2px;
}

.weapon-tag {
    font-size: 0.8em;
    padding: 2px 8px;
    background-color: #f0f0f0;
    border-radius: 4px;
    color: #666;
}

