.clubs-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
}

.create-club-button {
    background-color:  #0079d3;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease;
}

.create-club-button:hover {
    background-color: #0061a9;
}

.clubs-list {
    margin-top: 20px;
}

.club-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 16px;
    transition: all 0.2s ease;
}

.club-card:hover {
    border-color: #0079d3;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.club-link {
    display: block;
    padding: 16px;
    text-decoration: none;
    color: inherit;
}

.club-link h2 {
    margin: 0;
    margin-bottom: 8px;
    font-size: 18px;
    color: #1a1a1b;
}

.club-link p {
    margin: 0;
    color: #666;
    font-size: 14px;
}

.weapons-list {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.weapon-tag {
    background-color: #f6f7f8;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 12px;
    color: #666;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    border-radius: 8px;
    padding: 24px;
    width: 800px;
    max-width: 95vw;
    max-height: 90vh;
    overflow-y: auto;
}

.form-group {
    margin-bottom: 20px;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.input-prefix-container {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
}

.input-prefix {
    background-color: #f5f5f5;
    padding: 8px 12px;
    color: #666;
    border-right: 1px solid #ddd;
}

.input-prefix-container input {
    flex: 1;
    border: none;
    padding: 8px 12px;
    outline: none;
    text-align: left;
}

.input-prefix-container.error {
    border-color: #ff4500;
}

.weapons-checkboxes {
    display: flex;
    gap: 20px;
}

.weapons-checkboxes label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: normal;
}

.social-media-section,
.contact-info-section {
    border-top: 1px solid #eee;
    margin-top: 24px;
    padding-top: 24px;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
}

.cancel-button {
    padding: 8px 16px;
    border: 1px solid #ddd;
    background: white;
    border-radius: 4px;
    cursor: pointer;
}

.create-button {
    padding: 8px 16px;
    background:  #0079d3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.create-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.error-message {
    color: #dc3545;
    font-size: 0.9rem;
    margin-top: 4px;
}

.club-card-content {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;
}

.club-card-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #ddd;
}

.club-card-info {
    flex: 1;
}

.club-card-info h2 {
    margin: 0 0 0.5rem 0;
    font-size: 1.1rem;
}

.club-card-info p {
    margin: 0 0 0.5rem 0;
    color: #666;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 30px 0;
    padding: 20px 0;
}

.pagination-button {
    padding: 8px 16px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.pagination-button:hover:not(:disabled) {
    background-color: #e0e0e0;
}

.pagination-button:disabled {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
}

.page-indicator {
    font-size: 14px;
    color: #666;
}

.follower-count {
    color: #787c7e;
    font-size: 0.9em;
    margin: 4px 0;
}
