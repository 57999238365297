.create-post {
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 20px;
}

.create-post h3 {
    margin: 0 0 16px 0;
    font-size: 16px;
}

.post-title-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.post-content-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    font-size: 14px;
}

.submit-post-button {
    background-color: #0079d3;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
}

.submit-post-button:hover {
    background-color: #0061a9;
}

.create-post-container .quill {
    margin-bottom: 16px;
    border-radius: 4px;
}

.create-post-container .ql-container {
    min-height: 200px;
}

.create-post-container .ql-editor {
    min-height: 200px;
}

.create-post-container .ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #f8f9fa;
    border-color: #edeff1;
}

.create-post-container .ql-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-color: #edeff1;
}

.post-content {
    font-size: 14px;
    line-height: 1.5;
}

.post-content p {
    margin: 0 0 1em 0;
}

.post-content blockquote {
    border-left: 4px solid #ccc;
    margin: 0 0 1em;
    padding-left: 16px;
    color: #4a4a4a;
}

.post-content pre {
    background: #f8f9fa;
    border-radius: 4px;
    padding: 8px;
    margin: 0 0 1em;
    overflow-x: auto;
}

.post-content a {
    color: #0079d3;
    text-decoration: none;
}

.post-content a:hover {
    text-decoration: underline;
}
