.header {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.header-content {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    position: relative;
    box-sizing: border-box;
}

.logo a {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    text-decoration: none;
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 24px;
    white-space: nowrap;
}

.nav-links a {
    color: #333;
    text-decoration: none;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    white-space: nowrap;
}

.nav-links a:hover {
    color: #666;
}

.auth-buttons {
    display: flex;
    gap: 10px;
}

.login-button {
    background-color: white;
    color: #333;
    border: 1px solid #ddd;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.login-button:hover {
    background-color: #f8f9fa;
}

.signup-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.signup-button:hover {
    background-color: #0056b3;
}

.signout-button {
    background-color: white;
    color: #333;
    border: 1px solid #ddd;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.signout-button:hover {
    background-color: #f8f9fa;
}

/* Mobile styles */
@media (max-width: 768px) {
    .nav-links {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        z-index: 5;
    }

    .hamburger-menu.open {
        position: fixed;
        top: 40px;
        right: 40px;
        z-index: 1001;
    }

    .nav-links.open {
        transform: translateX(0);
    }

    .nav-links a {
        font-size: 1.5rem;
        margin: 1rem 0;
    }

    .search-box {
        width: 100%;
        margin: 10px 0;
    }

    .nav-links {
        flex-direction: column;
    }
}

.hamburger-menu {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
}

.hamburger-menu span {
    width: 30px;
    height: 3px;
    background: #888;
    border-radius: 2px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: center;
}

@media (max-width: 768px) {
    .hamburger-menu {
        display: flex;
    }

    /* X transformation styles */
    .hamburger-menu.open span:nth-child(1) {
        transform: translateY(11px) rotate(45deg);
        position: absolute;
    }

    .hamburger-menu.open span:nth-child(2) {
        opacity: 0;
    }

    .hamburger-menu.open span:nth-child(3) {
        transform: translateY(11px) rotate(-45deg);
        position: absolute;
    }

    .hamburger-menu.open {
        position: fixed;
        top: 40px;
        right: 40px;
        z-index: 1001;
    }
}

.search-selector {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.search-selector i {
    font-size: 12px;
    color: #666;
}

.search-selector:hover i {
    color: #333;
} 