.quill {
    background: white;
    border-radius: 4px;
    margin-bottom: 16px;
}

.ql-container {
    min-height: 150px;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

.ql-editor {
    min-height: 150px;
}

.ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-color: #edeff1;
    background: #f8f9fa;
}

.ql-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-color: #edeff1;
}

.ql-editor.ql-blank::before {
    color: #787c7e;
    font-style: normal;
}
