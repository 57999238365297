.user-menu-container {
    position: relative;
}

.avatar-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.nav-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #ddd;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    z-index: 1000;
    margin-top: 8px;
    overflow: hidden;
}

.menu-item {
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    color: #1c1c1c;
    font-size: 1rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1;
    letter-spacing: normal;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    margin: 0;
}

/* Reset any browser default button styles */
button.menu-item {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.menu-item:hover {
    background-color: #f0f0f0;
}

@media (max-width: 768px) {
    .user-menu-container {
        width: 100%;
    }

    .avatar-button {
        width: 100%;
        justify-content: center;
        padding: 10px 0;
    }

    .dropdown-menu {
        position: static;
        width: 100%;
        margin-top: 8px;
        box-shadow: none;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        border-left: none;
        border-right: none;
        border-radius: 0;
    }

    .menu-item {
        padding: 15px 0;
        text-align: center;
    }

    .sign-out {
        padding: 15px 0;
        text-align: center;
    }

    .nav-avatar {
        margin: 0 auto;
    }
}
