.contact-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 120px);
    padding: 20px;
}

.contact-container {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    border: 1px solid #d1d1d1;
    width: 100%;
    max-width: 600px;
}

.contact-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    font-family: inherit;
}

.form-group textarea {
    resize: vertical;
    min-height: 120px;
}

.submit-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.submit-button:hover {
    background-color: #0056b3;
}

.error {
    color: #dc3545;
    text-align: center;
    margin-bottom: 1rem;
}

.confirmation-message {
    text-align: center;
    padding: 1rem;
}

.confirmation-message h2 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 1rem;
} 