.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 120px);
    padding: 20px;
    background-color: #f5f5f5;
}

.login-container {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.login-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #1c1c1c;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.form-group input:focus {
    border-color: #0079d3;
    outline: none;
}

.email-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #0079d3;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.email-button:hover {
    background-color: #005fa3;
}

.email-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.auth-links {
    margin-top: 1.5rem;
    text-align: center;
}

.auth-links a {
    color: #0079d3;
    text-decoration: none;
}

.auth-links a:hover {
    text-decoration: underline;
}

.separator {
    margin: 0 0.5rem;
    color: #666;
}

.error {
    color: #ff4444;
    margin: 1rem 0;
    text-align: center;
}

.terms-agreement {
    margin: 15px 0;
}

.terms-agreement p {
    font-size: 0.75rem;
    color: #666;
    line-height: 1.4;
}

.terms-agreement a {
    color: #0079d3;
    text-decoration: none;
}

.terms-agreement a:hover {
    text-decoration: underline;
}
