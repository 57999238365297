.page-container section {
    margin-bottom: 2rem;
}

.page-container section p {
    color: #333;
    margin-bottom: 16px;
}

.page-container section ul {
    list-style-type: disc;
    padding-left: 24px;
    color: #333;
}

.page-container section li {
    margin-bottom: 12px;
    color: #333;
}

.page-container section li a {
    color:  #0079d3;
    text-decoration: none;
}

.page-container section li a:hover {
    text-decoration: underline;
}

.page-container section ul li::marker {
    color: #333;
}

.dashboard-description {
    line-height: 1.6;
    margin: 1.5rem 0;
    color: #333;
    font-size: 1.1rem;
}

.feed-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.feed-post {
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px 20px;
    transition: all 0.2s ease;
}

.feed-post:hover {
    border-color: #0079d3;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.community-link {
    color: #0079d3;
    text-decoration: none;
    font-weight: 600;
}

.empty-feed {
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 32px;
    text-align: center;
}


.post-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
}

.profile-photo {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.post-meta {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #666;
}

.username-link, .community-link {
    color: #1c1c1c;
    text-decoration: none;
    font-weight: 500;
}

.username-link:hover, .community-link:hover {
    color: #0079d3;
}

.post-meta-separator {
    color: #ccc;
}

.post-content-link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.post-title {
    margin: 0 0 8px 0;
    font-size: 18px;
    color: #1c1c1c;
    font-weight: 500;
}

.post-text {
    color: #1c1c1c;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 16px;
}

.post-actions {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #eee;
}

.action-button {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #666;
    text-decoration: none;
    font-size: 14px;
}

.action-button:hover {
    color: #0079d3;
}

.empty-feed p {
    margin-bottom: 16px;
    color: #666;
}

.primary-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: #0079d3;
    color: white;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
}

.primary-button:hover {
    background-color: #0061a9;
}

.feed-community-link {
    color: #1a1a1b;
    text-decoration: none;
    font-weight: 500;
}

.feed-community-link:hover {
    text-decoration: underline;
}
  