.footer {
    background-color: #f8f9fa;
    padding: 1.5rem 0;
    width: 100%;
    border-top: 1px solid #e7e7e7;
}

.footer-content {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.footer-links {
    display: flex;
    gap: 2rem;
}

.footer-links a {
    color: #666;
    text-decoration: none;
    font-size: 0.9rem;
}

.footer-links a:hover {
    color: #333;
    text-decoration: underline;
}

.footer-copyright {
    color: #666;
    font-size: 0.9rem;
} 