.setup-layout {
    min-height: 100vh;
    background-color: #f8f9fa;
}

.setup-header {
    background-color: #fff;
    border-bottom: 1px solid #e1e4e8;
    padding: 1rem;
}

.setup-logo {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.setup-content {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: calc(100vh - 64px);
} 