.message-input {
    width: 100%;
    min-height: 150px;
    padding: 12px;
    margin: 1rem 0;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    resize: vertical;
    font-family: inherit;
}

.modal-buttons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
}

.modal-buttons button {
    background-color: #6c757d;
    color: #ffffff !important;
    border: 1px solid #d1d1d1;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    min-width: 120px;
    font-weight: 500;
}

.modal-buttons button[type="submit"] {
    background-color: #0079d3;
    color: white;
    border: none;
}

.modal-buttons button[type="button"] {
    background-color: #6c757d;
    color: #ffffff !important;
    border: none;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    color: #333;
}

.confirmation-message {
    text-align: center;
    padding: 2rem;
}

.confirmation-message h2 {
    color: #28a745;
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.confirmation-message p {
    color: #666;
    margin-bottom: 1.5rem;
    font-size: 1rem;
}

.close-button {
    background-color: #6c757d;
    color: #ffffff !important;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    min-width: 120px;
    font-weight: 500;
    transition: background-color 0.2s ease;
    text-align: center;
    display: inline-block;
}

.close-button:hover {
    background-color: #5a6268;
    color: #ffffff !important;
}

.close-button:active {
    background-color: #545b62;
}

button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.modal-buttons button {
    color: #ffffff !important;
    min-width: 120px;
    font-weight: 500;
    text-align: center;
    display: inline-block;
}

.modal-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 1.5rem;
}

/* Specific styles for the close button */
.modal-buttons .close-button {
    background-color: #6c757d;
    border: none;
    color: #ffffff !important;
}

.modal-buttons .close-button:hover {
    background-color: #5a6268;
}

.modal-buttons .close-button:active {
    background-color: #545b62;
}
