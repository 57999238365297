.site-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}


.post-page-container {
    display: grid;
    grid-template-columns: 1fr 312px;
    gap: 24px;
}

.post-column {
    width: 100%;
}

.back-nav {
    margin-bottom: 16px;
}

.back-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    color: #1c1c1c;
}

.back-button:hover {
    background-color: #f8f9fa;
}

.back-button i {
    font-size: 12px;
}

.post-container {
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
}

.sidebar-column {
    width: 312px;
}

.post-header {
    margin-bottom: 16px;
}

.post-community {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 4px;
}

.post-meta {
    color: #787c7e;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.post-title {
    font-size: 20px;
    font-weight: 500;
    margin: 12px 0;
}

.post-content {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 16px;
}

.post-actions {
    border-top: 1px solid #edeff1;
    padding-top: 8px;
    display: flex;
    gap: 16px;
}

.action-button {
    background: transparent;
    border: none;
    color: #878a8c;
    padding: 8px;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
}

.action-button:hover {
    background: #f6f7f8;
    border-radius: 4px;
}

.loading, .error, .not-found {
    text-align: center;
    padding: 40px;
    font-size: 16px;
}

.error {
    color: #ff4500;
}

@media (max-width: 960px) {
    .post-page-container {
        grid-template-columns: 1fr;
    }

    .sidebar-column {
        display: none;
    }
}


.profile-photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
    border: 1px solid #ddd;
}

.post-header {
    display: flex;
    align-items: center;
}

.username-link,
.follower-username,
.following-username {
    color: #333;
    font-weight: 600;
    text-decoration: none;
}

.username-link:hover,
.follower-username:hover,
.following-username:hover {
    text-decoration: none;
}

.deleted-message {
    color: #787c7e;
    font-style: italic;
    padding: 12px 0;
}

.edit-post-form {
    margin: 16px 0;
}

.edit-post-form .post-title-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 20px;
    font-weight: 500;
}

.edit-actions {
    display: flex;
    gap: 12px;
    margin-top: 16px;
}

.cancel-button,
.save-button {
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
}

.cancel-button {
    background: transparent;
    border: 1px solid #ccc;
    color: #1c1c1c;
}

.save-button {
    background: #0079d3;
    border: none;
    color: white;
}

.save-button:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

.edit-indicator {
    color: #787c7e;
    font-size: 12px;
}

.username-link {
    color: #1c1c1c;
    text-decoration: none;
    font-weight: 600;
}

.post-meta-separator {
    color: #787c7e;
}

.post-date {
    color: #787c7e;
}

.edit-indicator {
    color: #787c7e;
}

/* Add these new rules for comments */
.comment-content {
    width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 100%;
    font-size: 14px;
    line-height: 1.5;
}

.comment-text {
    width: 100%;
    height: auto !important;
    overflow: visible !important;
}

.comment-text p {
    width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin: 0;
    padding: 0;
    line-height: 1.5;
}

.comment-container {
    height: auto !important;
    overflow: visible !important;
    padding: 16px;
    margin-bottom: 16px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
} 